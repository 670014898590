<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Franchisee Payments/Create</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo bx-flashing'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <!--              <div class="col-12 col-sm-6 col-md-3 col-lg-2">
                              <label>Show Per Page</label>
                              <VueMultiselect v-model="selectedPagination" class="" :options="pagination" :close-on-select="true"
                                              label="name" track-by="name" :show-labels="false" :allow-empty="false"/>
                            </div>-->
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <div class="form-group">
                  <label>Start Date</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker v-model="selectedFromDate" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue ,togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <div class="form-group">
                  <label>End Date</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker v-model="selectedToDate" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                  :max-date='new Date()' :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue ,togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <div class="form-group">
                  <label>Franchisee</label>
                  <VueMultiselect v-model="selectedFranchisee" class="" :options="franchiseeOptions"
                                  :close-on-select="true"
                                  placeholder="Select status" label="name" track-by="name" :show-labels="false"
                                  :allow-empty="false"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        :disabled="isButtonDisable"
                        @click="applyCustomerFilter(null)">Search
                </button>
              </div>
            </div>
            <div class="row pt-2">

            </div>
          </div>
        </div>
        <div class="users-list-table" v-if="franchiseeAppointmentPaymentsArray.length > 0">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'15%'}">REFERENCE ID</th>
                    <th class="position-relative" :style="{width:'15%'}">FRANCHISEE</th>
                    <th class="position-relative" :style="{width:'15%'}">PERCENTAGE</th>
                    <th class="position-relative" :style="{width:'15%'}">CLOSED DATE</th>
                    <th class="position-relative" :style="{width:'10%'}">JOB VALUE</th>
                    <th class="position-relative" :style="{width:'15%'}">Commission</th>
                  </tr>
                  </thead>
                  <tbody>

                  <tr v-for="(geeksPayment,index) in franchiseeAppointmentPaymentsArray" :key="index">
                    <td>
                      <span>{{ geeksPayment.appointment.reference }}</span>
                    </td>
                    <td>
                      <span class="text-capitalize">{{ userFullName(franchisee) }}</span>
                    </td>
                    <td>
                      <span>Royalty: {{ franchisee.royalty_percentage }}%</span><br>
                      <span>Marketing: {{ franchisee.marketing_percentage }}%</span><br>
                      <span>Operation: {{ franchisee.operation_percentage }}%</span>
                    </td>
                    <td>
                      <span>{{ geeksPayment.createdAt }}</span>
                    </td>
                    <td>
                      <span>${{ parseFloat(geeksPayment.appointmentTotalPrice / 100).toFixed(2) }}</span>
                    </td>
                    <td>
                      <span>$ {{ parseFloat(geeksPayment.franchiseePercentageFromAppointment / 100).toFixed(2)}}</span>
                    </td>
                  </tr>

                  </tbody>
                  <!--                  <tfoot>
                                    <tr>
                                      <th colspan="7">
                                        <button type="button" class="btn btn-warning" @click="submitBtnHandler">Submit</button>
                                      </th>
                                    </tr>
                                    </tfoot>-->
                </table>
              </div>
            </div>
          </div>
        </div>


        <div class="users-list-table" v-if="franchiseeOrderPaymentsArray.length > 0">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-bordered mb-2" v-for="(franchiseeOrder,index) in franchiseeOrderPaymentsArray"
                       :key="index">
                  <thead>
                  <tr v-if="index === 0">
                    <th class="position-relative" :style="{width:'10%'}">REFERENCE ID</th>
                    <th class="position-relative" :style="{width:'15%'}">PRODUCT SL</th>
                    <th class="position-relative" :style="{width:'15%'}">SELL PRICE</th>
                    <th class="position-relative" :style="{width:'15%'}">PURCHASE COST</th>
                    <th class="position-relative" :style="{width:'15%'}">PROFIT</th>
                    <th class="position-relative" :style="{width:'15%'}">COMMISSION</th>
                    <th class="position-relative" :style="{width:'15%'}">Product with commission</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(singleItem,itemIndex) in franchiseeOrder.orderProductList" :key="itemIndex">
                    <td :style="{width:'10%'}" v-if="itemIndex === 0" :rowspan="franchiseeOrder.orderProductList.length"
                        class="text-bold-500">
                      <!--                      <div class="custom-control custom-checkbox mb-2 mt-1">
                                              <input type="checkbox" class="custom-control-input" id="accept" value="1">
                                              <label for="accept" class="custom-control-label">Accept</label>
                                            </div>-->
                      {{ franchiseeOrder.order.reference }}
                    </td>
                    <td :style="{width:'15%'}" :class="singleItem.isFranchiseeProduct === true && 'bg-warning'">
                      {{ singleItem.productItem.serialNumber }}
                    </td>
                    <td :style="{width:'15%'}" :class="singleItem.isFranchiseeProduct === true && 'bg-warning'">
                      {{ parseFloat(singleItem.sellPrice / 100).toFixed(2) }}
                    </td>
                    <td :style="{width:'15%'}" :class="singleItem.isFranchiseeProduct === true && 'bg-warning'">
                      {{ parseFloat(singleItem.purchaseCost / 100).toFixed(2) }}
                    </td>
                    <td :style="{width:'15%'}" :class="singleItem.isFranchiseeProduct === true && 'bg-warning'">
                      {{ parseFloat(singleItem.profit / 100).toFixed(2) }}
                    </td>
                    <td :style="{width:'15%'}" :class="singleItem.isFranchiseeProduct === true && 'bg-warning'">
                      {{ parseFloat(singleItem.commission / 100).toFixed(2) }}
                    </td>
                    <td :style="{width:'15%'}" :class="singleItem.isFranchiseeProduct === true && 'bg-warning'">
                      {{ parseFloat(singleItem.productValue / 100).toFixed(2) }}
                    </td>
                  </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </div>


        <div class="col-12 col-sm-12 col-md-3 col-lg-3" v-if="franchiseeOrderPaymentsArray.length > 0 || franchiseeAppointmentPaymentsArray.length > 0">
          <button type="submit" class="btn btn-primary btn-block glow users-list-clear mb-0"
                  @click="submitBtnHandler">Submit
          </button>
        </div>
        <PaymentDetailsModal/>
      </section>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import PaymentDetailsModal from "@/views/backEnd/AccountAndFinance/Finance/AllPayments/includes/PaymentDetailsModal";
import {mapActions, mapGetters} from "vuex";

import VueMultiselect from "vue-multiselect";
import {DatePicker} from "v-calendar";
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";

export default {
  name: "CreateFranchiseePayments",
  components: {
    AppLayout,
    PaymentDetailsModal,
    DatePicker,
    VueMultiselect,
  },
  mixins: [Loader, ShowToastMessage],
  data() {
    return {

      selectedFromDate: '',
      selectedToDate: '',
      selectedFranchisee: {
        value: '',
        name: 'None'
      },
      selectedPagination: {
        value: '',
        name: 'Default'
      },
      technicalValue: {
        name: 'Any ',
        language: 'Any '
      },

      customerTypeSelectedValue: '',
      statusSelectedValue: '',

      currentGeekDetails: {
        id: "",
        name: "",
        address: "",
        type: "",
        phone: "",
        email: "",
        status: "",

      },
      getFranchiseeAppointmentParams: {
        status: 2, // 2: for closed status on appointment
        created_at: [],
        franchisee_id: '',
        with_relation: [
          'user',
          'appointment',
          'appointment.order',
          'technician.franchiseeTechnician.franchisee',
          'appointment.appointmentCharges',
          'appointment.service',
          'appointment.order.orderProductItems.productItemTransactions',
          'appointment.order.orderProductItems.productItem.product'
        ],
        paginate: 1,
        pagination: '',
        page: ''
      },
      getFranchiseesParams: {
        status: 1, // 1 for active technician
        with_relation: ['user', 'franchiseeTechnicians']
      },
      getSettingsParams: {
        type: ['default'],
        key: ['default_pagination', 'default_state'],
      },
      franchiseeAppointmentPaymentsArray: [],
      franchiseeOrderPaymentsArray: [],
      franchiseePercentage: 0,
      getFranchiseeOrderParams: {
        status: 5,
        updated_at: [],
        //where_has_franchisee_throw_appointment_technician_or_creator: this.selectedFranchisee.value,
        with_relation: [
          'orderCharges',
          'orderCreator.user.franchisee',
          'orderProductItems.productItem.purchaserUser',
          'orderProductItems.productItem.productItemTransaction'
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      appointmentHistories: 'appAppointmentHistories/appointmentHistories',
      orders: 'appOrders/orders',
      franchisees: 'appFranchisees/franchisees',
      franchisee: 'appFranchisees/franchisee',
      paginateLinks: 'appAppointmentHistories/paginateLinks',
    }),

    pagination() {
      return [{
        value: this.$store.getters['appSettings/settingDefaultPagination'].value,
        name: 'Default'
      }, {
        value: 25,
        name: '25 Entries'
      }, {
        value: 50,
        name: '50 Entries'
      }, {
        value: 100,
        name: '100 Entries'
      }];
    },

    franchiseeOptions() {
      let activeFranchisees = this.franchisees.map((franchisee) => {
        let franchiseeId = franchisee.id;

        let firstName = franchisee?.user?.first_name ?? '';
        let lastName = franchisee?.user?.last_name ?? '';
        let fullName = `${firstName} ${lastName}`;

        return {
          value: franchiseeId,
          name: fullName
        };
      });

      return [{value: '', name: `None`}, ...activeFranchisees];
    },

    isButtonDisable() {
      return !(this.selectedFromDate !== '' && this.selectedToDate !== '' && this.selectedFranchisee.value !== '');
    }
  },
  watch: {
    selectedPagination: function (pagination) {
      this.getFranchiseeAppointmentParams.pagination = pagination.value;
    },
    selectedFromDate(value) {
      this.getFranchiseeAppointmentParams.created_at[0] = value + ' 00:00:00';
      this.getFranchiseeOrderParams.updated_at[0] = value + ' 00:00:00';
    },
    selectedToDate(value) {
      this.getFranchiseeAppointmentParams.created_at[1] = value + ' 00:00:00';
      this.getFranchiseeOrderParams.updated_at[1] = value + ' 00:00:00';
    },
    selectedFranchisee(currentValue) {
      this.getFranchiseeAppointmentParams.where_has_technician_franchisee_technician_franchisee_id = currentValue.value;
      this.getFranchiseeOrderParams.where_has_franchisee_throw_appointment_technician_or_creator = currentValue.value;
    }
  },
  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getFranchisees: 'appFranchisees/getFranchisees',
      getFranchisee: 'appFranchisees/getFranchisee',
      getAppointmentHistories: 'appAppointmentHistories/getAppointmentHistories',
      getOrderCreators: 'appOrderCreators/getOrderCreators',
      getOrders: 'appOrders/getOrders',
      postFranchiseePayments: 'appFranchiseePayments/postFranchiseePayment',

      resetFranchisees: 'appFranchisees/resetFranchisees',
      resetAppointmentHistories: 'appAppointmentHistories/resetAppointmentHistories',
      resetOrders: 'appOrders/resetOrders',
    }),
    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },

    async getFranchiseeAppointmentList(params) {
      this.loader(true);
      await this.getAppointmentHistories(params)
          .then((response) => {
            if (response && response.message && response.status !== 200) {
              this.loader(false);
              this.showToastMessage(response);
            } else {
              this.loader(false);
            }
          });
    },

    async getOnlyFranchiseeOrderList(params) {
      this.loader(true);
      await this.getOrders(params)
          .then((response) => {
            if (response && response.message && response.status !== 200) {
              this.loader(false);
              this.showToastMessage(response);
            } else {
              this.loader(false);
            }
          });
    },

    userFullName(customerObj) {
      return `${customerObj.user.first_name} ${customerObj.user.last_name}`;
    },

    async getSingleFranchisee(selectedId) {
      let paramObj = {
        id: selectedId,
        params: {with_relation: ['user']},
      };

      await this.getFranchisee(paramObj).then(async (response) => {

        if (response.status === 404) {
          this.commitNotFoundRouteStatus(true);
          return;
        }
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });

    },

    async generateGeeksData() {
      this.franchiseePercentage = this.calculateFranchiseePercentage(this.franchisee);

      this.franchiseeAppointmentPaymentsArray = this.appointmentHistories.map((singleRow) => {
        let technician = singleRow.technician;
        let appointment = singleRow.appointment;
        let appointmentUnitPrice = singleRow?.appointment?.unit_price ?? 0

        // unset schedule key from  technician obj
        if (singleRow.technician && singleRow.technician.schedule) {
          delete singleRow.technician.schedule;
        }
        // appointment total price from appointment charges
        let appointmentTotalPrice = this.calculateAppointmentTotal(singleRow.appointment.appointmentCharges);
        let commission = (appointmentTotalPrice * this.franchiseePercentage/100);
        let franchiseePercentageFromAppointment = appointmentTotalPrice - commission

        return {
          creator: singleRow?.user ?? {},
          panel: singleRow.panel,
          status: singleRow.status,
          createdAt: singleRow.created_at,
          appointmentUnitPrice: appointmentUnitPrice,
          technician: technician,
          appointment: appointment,
          appointmentTotalPrice: appointmentTotalPrice,
          commission: commission,
          franchiseePercentageFromAppointment: franchiseePercentageFromAppointment
        }
      });

      this.franchiseeOrderPaymentsArray = this.orders.map((singleRow) => {
        let orderProductList = this.calculateOrderProductItemDetails(singleRow.orderProductItems);
        return {
          order: {
            id: singleRow.id,
            reference: singleRow.order_no,
            order_date: singleRow.order_date,
            delivery_date: singleRow.delivery_date,
          },
          orderProductList: orderProductList,
          orderTotalPrice: this.calculateOrderCharges(singleRow)
        }
      })
    },

    calculateAppointmentTotal(singleAppointmentCharges) {
      const decreaseType = ['Discount', 'Coupon']
      return singleAppointmentCharges.reduce(function (acc, cur) {
        acc = (decreaseType.includes(cur.type)) ? acc - cur.amount : acc + cur.amount
        return acc
      }, 0);


    },

    calculateFranchiseePercentage(franchiseeObj) {
      return parseFloat(franchiseeObj.royalty_percentage + franchiseeObj.marketing_percentage + franchiseeObj.operation_percentage).toFixed(2);
    },

    calculateOrderProductItemDetails(singleOrderProductItems) {
      return singleOrderProductItems.map((row) => {
        let productValue = 0;
        let isFranchiseeProduct = !!row.productItem?.purchaserUser?.id;
        let purchaseCost = row.productItem.purchase_cost;
        let profit = row.productItem?.productItemTransaction?.sell_price - row.productItem.purchase_cost;
        let commission = (row.productItem?.productItemTransaction?.sell_price - row.productItem.purchase_cost) * (this.franchiseePercentage / 100)
        if (isFranchiseeProduct) {
          productValue = purchaseCost + commission
        } else {
          productValue = commission
        }
        return {
          productItem: {
            id: row.productItem.id,
            serialNumber: row.productItem.serial_number,
          },
          isFranchiseeProduct: isFranchiseeProduct,
          purchaseCost: purchaseCost,
          sellPrice: row.productItem?.productItemTransaction?.sell_price,
          profit: profit,
          commission: commission,
          productValue: productValue,
        }
      });
    },

    calculateOrderCharges(singleOrder) {
      const decreaseType = ['Discount', 'Coupon']
      if (singleOrder.status === 'Pending') {
        return singleOrder?.details?.grand_total ?? 0;
      } else {
        return singleOrder.orderCharges.reduce(function (accu, cur) {
          accu = (decreaseType.includes(cur.type)) ? accu - cur.amount : accu + cur.amount
          return accu
        }, 0);
      }

    },

    generateTechnicianCommissionPaymentsData() {
      return this.geeksPaymentsArray.map((row) => {
        return {
          id: row.appointment.id,
          reference: row.appointment.reference,
          bookingDate: row.appointment.date + ' ' + row.appointment.time,
          type: row.appointment.type,
          serviceName: row.appointment.service.name,
          unitPrice: row.appointmentUnitPrice,
          totalPrice: row.appointmentTotalPrice,
          servicePrice: row.appointmentServicePrice,
          isAppointmentTagOrder: row.isAppointmentTagOrder,
          technicianWages: row.technicianWagesFromAppointment,
          closingDate: row.createdAt,
          order: {
            commissionRate: row.productCommissionRate,
            commissionType: row.productCommissionType,
            commission: row.orderProductCommission,
          },
          technician: {
            salaryType: row.technicianSalaryType,
            salaryAmount: row.technicianSalaryAmount,
          }
        }
      });
    },

    async createFranchiseePayments(forPaymentType,submitData) {
      const data = {
        franchisee_id: this.franchisee.id,
        details: JSON.stringify(submitData),
        royalty_percentage: this.franchisee.royalty_percentage,
        marketing_percentage: this.franchisee.marketing_percentage,
        operation_percentage: this.franchisee.operation_percentage,
        from_date: this.selectedFromDate,
        to_date: this.selectedToDate,
        payment_for: forPaymentType,
      };

      await this.postFranchiseePayments(data).then((response) => {
        console.log(response, "franchisee payment percentage");
        if (response.status !== 201) {
          if (response.message) {
            this.showToastMessage(response);
          }
          return false;
        }
        return true;

      });
    },

    async submitBtnHandler() {
      let status = false;
      this.loader(true);
      if(this.franchiseeAppointmentPaymentsArray.length > 0){

      status =  await this.createFranchiseePayments(0, this.franchiseeAppointmentPaymentsArray);
      }
      if(this.franchiseeOrderPaymentsArray.length > 0){

      status =  await this.createFranchiseePayments(1, this.franchiseeOrderPaymentsArray);
      }
      this.loader(false);
      if(status){
        this.showToastMessage({type: 'success', message: 'Franchisee payment create successfully'});
      }

      /*this.$router.replace({
        name: 'appTechnicianPaymentList',
      });*/
    },

    async applyCustomerFilter(pageNumber) {
      this.getFranchiseeAppointmentParams.page = pageNumber;
      await this.getSingleFranchisee(this.selectedFranchisee.value);
      await this.getFranchiseeAppointmentList(this.getFranchiseeAppointmentParams);
      await this.getOnlyFranchiseeOrderList(this.getFranchiseeOrderParams);
      await this.generateGeeksData();
    },
  },
  async mounted() {
    await this.getSettingList(this.getSettingsParams);
    await this.getFranchisees(this.getFranchiseesParams);
  },
  async beforeMount() {
    await this.resetFranchisees();
    await this.resetAppointmentHistories();
    await this.resetOrders();
  }

}
</script>

<style scoped>

@media only screen and (max-width: 1337px) and (min-width: 320px) {
  .table {
    white-space: nowrap !important;
  }

}
</style>
