<template>
<div class="modal fade " id="paymentDetailsModal" tabindex="-1" role="dialog" aria-labelledby="customerDetailsModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h5 class="modal-title white" id="">Quick view</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <table class="table table-striped">

                    <tbody>
                        <tr>
                            <th>SL</th>
                            <th>Pay Section</th>
                            <th>Pay Type</th>
                            <th class="text-right">Amount</th>
                            <th>Date &amp; Time</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>appointment</td>
                            <td>credit card</td>
                            <td class="text-right">$171.60</td>
                            <td>09 Dec 2021, 10:28 AM</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer border-0 py-1">
                <button type="button" class="btn btn-primary" data-dismiss="modal">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Close</span>
                </button>

            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "PaymentDetailsModal",

}
</script>

<style>

</style>
